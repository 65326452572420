.mainDiv {
	.idDiv {
		background-color: orange;
		padding: 0 2px;
		margin: 5px;
		border-radius: 5px;
		color: white;
		width: 95%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.removeIconDiv {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		> svg {
			font-size: 15px;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel,
	.dropdownLabel,
	.texAreaLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.selectSearchError {
		color: red;
		font-size: 13px;
		font-weight: 400;
	}
	.dropdownRow {
		margin: 25px 0;
	}
	.row {
		margin: 30px 0;
	}
	.textArea {
		resize: none;
		width: 100%;
		padding: 10px;
		height: 100px;
	}
	.content {
		padding: 20px 50px;
	}
	.tagsContainer {
		height: 100px;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(173, 172, 172, 0.178);
		padding: 4px;
		margin-top: 5px;
	}
	.idDiv {
		background-color: orange;
		padding: 0 2px;
		margin: 5px;
		border-radius: 5px;
		color: white;
		width: fit-content;
	}
	.tagRow {
		margin-top: 17px;
	}
	.buttonContainer {
		padding-left: 50px;
		text-align: left;
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-start;
	}
	@media screen and (min-width: 600px) {
		.buttonContainer {
			padding-left: 0;
			text-align: left;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
		}
		.row {
			margin: 25px 0;
		}
		.textAreaRow {
			margin: 50px 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.texAreaLabel {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-top: 10px !important;
			height: 100%;
		}
	}
}

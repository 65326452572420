.mainDiv {
	padding: 20px;
	.totalNumber {
		font-weight: 400;
	}
	.detailIconDiv {
		background-color: black;
		width: 40px;
		height: 40px;
		color: white;
		text-align: center;
		position: absolute;
		top: -20px;
		left: -20px;
		align-items: center;
		transform: rotate(-45deg);
		padding-top: 23px;

		> span {
			transform: rotate(45deg);
			display: inline-block;
			font-weight: 600;
		}
		&:hover {
			outline: none;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}
	.leftAlignCell {
		text-align: left;
	}
	.fitTitleDivCheckbox {
		height: fit-content !important;
		padding-bottom: 0 !important;
	}
	.fitTitleDiv {
		height: fit-content !important;
		padding-bottom: 0 !important;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.titleDiv {
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel,
	.dataLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.textAlignLeft {
		text-align: left;
	}
	.noRow {
		width: 100%;
		text-align: center;
		margin: 20px;
		font-weight: 500;
		color: red;
		font-size: 16px;
	}
	.inlineButton {
		font-size: 13px;
		padding: 2px;
		border: 1px solid orange;
		border-radius: 5px;
		background-color: white;
		color: orange;
		outline: none;
		width: fit-content;
		margin: 5px;
		&:hover {
			outline: none;
			background-color: orange;
			color: white;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}

	.inlineEditButton {
		color: gray;
		border: 1px solid gray;
		&:hover {
			background-color: gray;
			color: white;
		}
	}

	.inlineDeleteButton {
		color: red;
		border: 1px solid red;
		&:hover {
			background-color: red;
			color: white;
		}
	}
	.inlineTag {
		display: inline-flex;
		font-size: 13px;
		padding: 1px 4px;
		width: fit-content;
		border-radius: 5px;
		margin: 4px;
		color: white;
	}
	.passengercanceledTag,
	.suspendedTag,
	.failedTag,
	.shopsupportTag,
	.rejectedTag {
		background-color: rgb(212, 28, 59);
	}
	.destinationTag,
	.paidTag,
	.deliveredorderTag {
		background-color: rgb(9, 122, 9);
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.tableContainer {
		width: 100%;
	}
	.selectSearchError {
		color: red;
		font-size: 12px;
	}
	.paymentRow {
		background-color: rgba(209, 209, 209, 0.295);
		padding: 4px;
		padding-left: 10px !important;
		margin: 10px 0px;
		.paymentInfoWrapper {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
		.paymentInfo {
			margin-top: 10px;
		}
		.checkboxContainer,
		.iconContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;
		}
		.iconContainer {
			// margin-top: 11px;
		}

		.paymentId {
			&:hover {
				cursor: pointer;
			}
			font-weight: 400;
		}
		.paymentDate {
			font-size: 11px;
		}
	}
	.data {
		padding: 20px;
		.dataRow {
			margin: 20px 0;
			.dataValue {
				font-weight: 400;
				margin-top: 20px;
			}
		}
	}
	.dropdownRow {
		margin-top: 15px;
	}
	.dropdownContainer {
		margin-top: 10px;
	}
	.row {
		margin: 15px 0;
	}
	.dateRow {
		padding: 0 17px;
	}
	.dateLabel,
	.dateRow {
		margin: 15px 0;
	}
	.percentIcon {
		// color: rgba(34, 33, 33, 0.733);
		color: red;
		font-size: 10px;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.tagRow {
		margin-top: 17px;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	@media screen and (min-width: 600px) {
		.filterDiv {
			flex-direction: row-reverse;
		}
		.rowConfigDriv {
			flex-direction: row;
		}
		.itemsNumberDiv {
			align-self: flex-end;
		}
		.data {
			.dataRow {
				.dataValue {
					margin-top: 0;
				}
			}
		}

		.dropdownRow {
			margin-top: 20px;
		}
		.dropdownContainer {
			text-align: right;
			padding-right: 5px;
			margin-top: 0;
		}
		.content {
			padding: 30px 100px;
		}
		.buttonContainer {
			text-align: center;
		}
		.dateRow {
			padding: 0;
			margin: 30px 0;
		}
		.row {
			margin: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
			:first-child {
				margin-top: 20px;
			}
			:nth-child(2) {
				margin-top: 42px;
			}
		}
		.tagRow {
			height: 100px;
		}
		.expireContainer {
			margin-bottom: 40px;
		}
	}
	@media screen and (min-width: 960px) {
		.content {
			padding: 30px 15px;
		}
		.tagRow {
			margin-top: 27px;
		}
	}
	@media screen and (min-width: 1280px) {
		// .form,
		// .data {
		//   background-color: rgba(255, 255, 255, 0.295);
		//   padding: 20px 0;
		// }
		// .data {
		//   padding: 20px 50px;
		//   height: 100%;
		//   display: flex;
		//   flex-direction: column;
		//   justify-content: space-evenly;
		//   .dataRow {
		//     .dataValue {
		//       text-align: right;
		//     }
		//   }
		// }
	}
}


.mainDiv{
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url("../../assets/img/register-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .icon{
        &:hover{
            cursor: pointer;
        }
    }
    .title{
        text-align: center;
        width: 100%;
    }
    .inputLabel{
        color: rgba(70, 68, 68, 0.836);
        text-align: left;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 10px;
        margin-top: 15px;
    }
    .buttonContainer{
        margin: 15px 0 5px 0;
        
    }
    .dropdownContainer{
        margin: 20px 0;
        .dropdownWrapper{
            margin-top: 20px;
            text-align: left;
        }
    }
    @media screen and (min-width: 600px){
        overflow: hidden;
        .inputLabel{
            margin-top: 0;
            padding-bottom: 15px;
            padding-left: 15px;
        }
        .dropdownWrapper{
            text-align: center !important;
            margin-top: 0 !important;
        }
    }
}
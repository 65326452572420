.mainDiv {
	.selectSearchError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		text-align: right;
	}
	.selectSearchRow {
		padding: 10px 5px !important;
		margin: 15px 0 !important;
	}
	.sortDiv {
		font-size: 14px;
		font-weight: 400;
		&:hover {
			cursor: pointer;
		}
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.multipleButtonDiv {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	.multipleAddDiv {
		background-color: rgba(223, 218, 218, 0.171);
		padding: 15px;
	}
	.toggleDiv {
		text-align: center;
		display: flex;
		margin: 10px 0;
		flex-direction: row;
		justify-content: flex-start;
		> button {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			outline: none;
			border: none;
			background-color: rgb(226, 226, 226);
			font-weight: 400;
			font-size: 15px;
			padding: 5px 10px;
			border-radius: 15px;
			color: rgb(80, 79, 79);
			&:hover {
				cursor: pointer;
				color: black;
			}
			&:focus {
				outline: none;
				border: none;
			}
			.buttonSentence {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}
	}
	.downloadButton {
		color: green !important;
		border: 1px solid green;
		padding: 10px 15px;
		background-color: inherit;
		&:hover {
			cursor: pointer;
		}
	}
	.excelLoadingDiv {
		width: 100px;
	}
	.titleDiv {
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.leftAlignCell {
		text-align: left;
	}
	.fitTitleDivCheckbox {
		height: fit-content !important;
		padding-bottom: 0 !important;
	}
	.fitTitleDiv {
		height: fit-content !important;
		padding-bottom: 0 !important;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.titleDivSmall {
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.titleDivAction {
		margin-bottom: 80px;
		text-align: right;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.buttonContainer {
		height: 100%;
		align-items: center;
	}
	.checkBoxDiv {
		align-self: center;
	}
	.downloadButton {
		color: green !important;
		border: 1px solid green;
		padding: 10px 15px;
		background-color: inherit;
		&:hover {
			cursor: pointer;
		}
	}
	.excelLoadingDiv {
		width: 100px;
	}
	.exportButton {
		color: rgb(95, 93, 93);
		border: 1px solid gray;
		padding: 10px 15px;
		background-color: inherit;
		&:hover {
			cursor: pointer;
		}
	}
	.inlineButton {
		font-size: 13px;
		padding: 2px;
		border: 1px solid orange;
		border-radius: 5px;
		background-color: white;
		color: orange;
		outline: none;
		width: fit-content;
		margin: 5px;
		&:hover {
			outline: none;
			background-color: orange;
			color: white;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}

	.inlineEditButton {
		color: gray;
		border: 1px solid gray;
		&:hover {
			background-color: gray;
			color: white;
		}
	}

	.inlineDeleteButton {
		color: red;
		border: 1px solid red;
		&:hover {
			background-color: red;
			color: white;
		}
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.tagRow {
		margin-top: 17px;
	}
	.tagsContainer {
		background-color: rgba(185, 184, 184, 0.452);
	}
	.tagsContainer {
		width: 80%;
		height: 100px;
		overflow-x: auto;
		overflow-y: auto;
	}
	.imageContainer {
		> img {
			width: 100px;
			height: 100px;
		}
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.dropdownRow {
		margin: 20px 0;
	}
	.label,
	.inputLabel,
	.colorLabel,
	.dropdownLabel,
	.imageLabel,
	.tagLabel,
	.uploadImageLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.inputLabel {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 18px;
		height: 100%;
	}
	.imageInputLabel {
		padding-bottom: 0;
		padding-top: 12px;
	}
	.imageLabel {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 100%;
	}
	.row,
	.inputRow {
		background-color: rgba(223, 218, 218, 0.171);
		padding: 10px 15px;
		margin: 10px 0;
	}
	.idContainer,
	.textContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		margin: 7px 0;
	}
	.buttonContainer {
		button {
			width: fit-content;
		}
	}

	@media screen and (min-width: 600px) {
		.tagRow {
			height: 100px;
		}
		.checkBoxDiv {
			align-self: center;
		}
		.categoryButtonContainer {
			align-items: center;
			text-align: right;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			height: 100%;
			> button {
				width: fit-content;
				height: fit-content;
			}
		}
		.colorLabel {
			padding-top: 40px;
		}
		.dropdownContainer {
			text-align: right;
		}
		.uploadImageLabel {
			padding-top: 40px;
		}
		.uploadImageContainer {
			padding-top: 40px;
		}
		.dropdownLabel {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 13px;
			height: 100%;
		}
		.buttonContainer {
			text-align: right;
		}
		.idContainer,
		.textContainer,
		.buttonContainer {
			margin: 0 0;
		}
	}
	@media screen and (min-width: 960px) {
		.tagRow {
			margin-top: 27px;
		}
		.buttonContainer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
		.inputButtonContainer {
			text-align: left;
			padding-top: 15px;
		}
		.middleButtonContainer {
			text-align: center !important;
		}
	}
	@media screen and (min-width: 1280px) {
		.tagRow {
			margin-top: 35px;
		}
		.tagLabel {
			padding-top: 10px;
		}
	}
}

:global {
	.react-tagsinput-input {
		width: fit-content !important;
	}
}

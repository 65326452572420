.mainDiv {
	.toggleDiv {
		display: flex;
		flex-direction: row;
        justify-content: flex-start;
        margin: 10px 0;
		> button {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			outline: none;
			border: none;
			background-color: rgb(226, 226, 226);
			font-weight: 400;
			font-size: 15px;
			padding: 5px 10px;
			border-radius: 15px;
			color: rgb(80, 79, 79);
			&:hover {
				cursor: pointer;
				color: black;
			}
			&:focus {
				outline: none;
				border: none;
			}
			.buttonSentence {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}
	}
	.addButton {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
	}
	.sentence {
		font-weight: 400;
		margin: 20px 0;
		font-size: 17px;
	}
	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 30px 0;
		text-align: center;
	}
}

.mainDiv {
	overflow-x: hidden;
	padding-top: 15vh;
	padding-bottom: 20px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgb(223, 223, 223);
	// background-image: url("../../assets/img/register-background.jpg");
	// background-repeat: no-repeat;
	// background-size: cover;
	// background-position: center;
	display: flex;
	flex-direction: column;
	// justify-content: space-around;
	.listContainer {
		font-size: 16px;
		font-weight: 400;
		margin: 35px 0;
		color: black;
		> ol {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		> ol > li {
			margin: 20px 0;
		}
	}
	.listItem {
		margin: 15px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
	.buttonContainer {
		text-align: center;
		margin: 15px 0;
	}
	.title {
		text-align: center;
		width: 100%;
	}
	.whiteCard {
		border-radius: 2px;
		background-color: white;
		padding: 0 15px 5px 15px;
	}
	.header {
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.headerSentence {
		background-color: black;
		color: white;
		font-size: 19px;
		font-weight: 500;
		width: fit-content;
		padding: 20px 30px;
	}
	@media screen and (min-width: 600px) {
		// position: fixed;
		overflow-x: hidden;
	}
	@media screen and (min-width: 960px) {
	}
}

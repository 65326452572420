.a{
    display: flex !important;
    color: inherit;
    padding-left: 10px;
    >svg{
        opacity: 0.8;
    }
    >div{
        padding: 0 16px !important;
    }
    &:hover{
        cursor: pointer;
        color: inherit;
        >svg{
            opacity: 1 ;
        }
    }
}
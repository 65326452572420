.mainDiv {
	input {
		font-size: 16px;
		font-weight: 400;
	}
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.inlineButton {
		font-size: 13px;
		padding: 2px;
		border: 1px solid orange;
		border-radius: 5px;
		background-color: white;
		color: orange;
		outline: none;
		width: fit-content;
		margin: 5px;
		&:hover {
			outline: none;
			background-color: orange;
			color: white;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}

	.inlineEditButton {
		color: gray;
		border: 1px solid gray;
		&:hover {
			background-color: gray;
			color: white;
		}
	}
	.inlineDeleteButton {
		color: red;
		border: 1px solid red;
		&:hover {
			background-color: red;
			color: white;
		}
	}
	.productDiv {
		display: flex;
		flex-direction: revert;
		justify-content: space-between;
		background-color: white;
		padding: 5px;
		margin: 5px 0;
		border-radius: 5px;
	}
	.productTitle {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		font-weight: 400;
		width: 60%;
		overflow: hidden;
	}
	.toggleDiv {
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		> button {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			outline: none;
			border: none;
			background-color: rgb(226, 226, 226);
			font-weight: 400;
			font-size: 15px;
			padding: 5px 10px;
			border-radius: 15px;
			color: rgb(80, 79, 79);
			&:hover {
				cursor: pointer;
				color: black;
			}
			&:focus {
				outline: none;
				border: none;
			}
			.buttonSentence {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}
	}
	.upperSentence {
		margin: 15px 0;
	}
	.content {
		padding: 20px 20px;
	}

	.subContainer {
		margin: 10px 0;
		padding: 20px;
	}
	.subMenuTitle {
		margin: 20px 0;
		font-size: 16px;
		font-weight: 400;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.row {
		margin: 15px 0;
	}
	.tagRow {
		// height: 100px;
		margin: 20px 0;
	}
	.buttonsContainer {
		margin-top: 40px;
	}
	.tagLabel {
		margin-bottom: 15px;
	}
	.linkButtonDiv {
		text-align: center;
	}
	.filterDiv {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		position: -webkit-sticky;
		position: sticky;
		z-index: 100;
		top: 0;
		background-color: white;
		> div {
			display: flex;
		}
	}
	.rowConfigDriv {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.itemsNumberDiv {
		font-size: 12px;
		color: gray;
		font-weight: 400;
	
		> span {
			color: black;
			font-weight: 500;
		}
	}
	.detailIconDiv {
		background-color: black;
		width: 40px;
		height: 40px;
		color: white;
		text-align: center;
		position: absolute;
		top: -25px;
		left: -25px;
		align-items: center;
		transform: rotate(-45deg);
		padding-top: 25px;
	
		> span {
			transform: rotate(45deg);
			display: inline-block;
			font-weight: 600;
			font-size: 10px;
		}
		&:hover {
			outline: none;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}
	.titleDiv {
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.titleDivAction {
		margin-bottom: 80px;
		text-align: right;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.checkboxDivHeader {
		text-align: center;
	}
	@media screen and (min-width: 600px) {
		.filterDiv {
			flex-direction: row-reverse;
		}
		.rowConfigDriv {
			flex-direction: row;
		}
		.itemsNumberDiv {
			align-self: flex-end;
		}
		.subMenuTitle {
			text-align: left;
			margin-bottom: 0;
			margin-top: 0px;
		}
		.buttonsContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
			> button {
				margin: 0 5px;
			}
		}
		.tagRow {
			// height: 100px;
			margin: 20px 0;
		}
		.tagLabel {
			padding-top: 15px;
			// margin-bottom: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
			padding-bottom: 18px;
		}
	}
	@media screen and (min-width: 960px) {
		.subContainer {
			padding: 20px 40px;
		}
	}
}

:global {
	.react-swipeable-view-container {
		> div {
			overflow-x: hidden !important;
			overflow-y: auto;
			padding: 0 10px;
			width: 100% !important;
		}
	}
}

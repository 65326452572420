.mainDiv {
    width: 90%;
    padding: 0;
    position: relative;
	.header {
		position: sticky;
		top: 0;
		color: white;
		background-color: rgb(83, 83, 83);
    padding-bottom: 5px;
    &:first-child {
			text-align: right;
		}
	}
    .content {
      padding: 20px 50px;
    }
    .title {
      text-align: center;
      > h4 {
        font-weight: 400;
      }
    }
    .rowContainer{
      margin-top: 5px;
  }
  .label{
      font-weight: 400;
  }
    .footer {
      margin-top: 20px;
      display: none;
      flex-direction: row;
      justify-content: space-around;
      padding: 5px 0;
    }
  .value{
    text-align: left;
  }
    @media screen and (min-width: 600px) {
      width: 450px;
      .value{
        text-align: right;
    }
    }
    @media screen and (min-width: 960px) {
      width: 850px;
    }
  }
.mainDiv {
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.customizeInput {
		width: 100%;
		padding: 8px;
		&:hover {
			cursor: pointer;
		}
	}
	.driversSelectSearchDiv {
		position: relative;
		width: 100%;
		.drviersInputSearch {
			width: 100%;
			padding: 5px;
			font-size: 14px;
			border-radius: 4px;
			border: 1px solid rgb(218, 218, 218);
		}
		.driversBox {
			z-index: 100;
			width: 100%;
			padding: 1px;
			background-color: rgba(231, 231, 231, 0.993);
			position: absolute;
			top: 30px;
			height: fit-content;
			max-height: 300px;
			border: 1px solid rgb(218, 218, 218);
			border-radius: 4px;
			overflow: auto;
			.driversItemDiv {
				background-color: white;
				margin: 2px 0;
				border-radius: 1px;
				padding: 4px;
				border: none;
				color: black;
				font-weight: 400;
				&:hover {
					cursor: pointer;
					background-color: rgb(212, 212, 212);
				}
			}
			.driversLoadingDiv {
				height: 100px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}
	}
	.label,
	.dateLabel,
	.imageLabel,
	.checkboxLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.selectSearchError {
		color: red;
		font-size: 13px;
		font-weight: 400;
	}
	.buttonContainer {
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 20px;
	}
	.carImageLabel {
		margin-bottom: 20px;
	}
	.dropdownRow {
		margin-top: 15px;
	}
	.dropdownRowBrand {
		margin-top: 15px;
	}
	.dropdownContainer {
		margin-top: 10px;
	}
	.row {
		margin: 15px 0;
	}
	.dateLabel,
	.dateRow {
		margin: 15px 0;
	}
	.percentIcon {
		// color: rgba(34, 33, 33, 0.733);
		color: red;
		font-size: 10px;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.tagRow {
		margin-top: 17px;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	.useCaseError {
		color: red;
		font-size: 13px;
		font-weight: 400;
	}
	.imageError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		margin: 5px;
	}
	.imageRow {
		margin: 15px 0;
	}
	.insuranceImageContainer {
		margin-top: 15px;
	}
	@media screen and (min-width: 600px) {
		.buttonContainer {
			text-align: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 20px;
		}
		.insuranceImageContainer {
			margin-top: 0;
			text-align: right;
			> div {
				width: 50%;
			}
		}
		.checkboxLabel,
		.imageLabel {
			padding-top: 10px;
		}
		.imageRow {
			margin: 30px 0;
		}
		.checkboxRow {
			margin-top: 20px;
		}
		.useCaseCheckboxRow {
			margin-top: 70px;
			margin-bottom: 0;
		}
		.dropdownRow {
			margin-top: 20px;
		}
		.dropdownRowBrand {
			margin-top: 30px;
		}
		.dropdownContainer {
			text-align: right;
			padding-right: 5px;
			margin-top: 0;
		}
		.content {
			padding: 30px 100px;
		}
		.dateRow {
			margin: 30px 0;
		}
		.row {
			margin: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
			:first-child {
				margin-top: 20px;
			}
			:nth-child(2) {
				margin-top: 42px;
			}
		}
		.tagRow {
			height: 100px;
		}
		.expireContainer {
			margin-bottom: 40px;
		}
	}
	@media screen and (min-width: 960px) {
		.insuranceImageContainer {
			text-align: right;
		}
		.content {
			padding: 30px 15px;
		}
		.tagRow {
			margin-top: 27px;
		}
	}
	@media screen and (min-width: 1280px) {
		.insuranceImageContainer {
			text-align: right;
			> div {
				width: 280px;
			}
			> img {
				border-radius: 0 !important;
			}
		}
		.carImageContainer {
			> div {
				width: 200px;
			}
		}
		.carImageLabel {
			margin-bottom: 30px;
			text-align: center;
		}
		.leftSide,
		.rightSide {
			background-color: rgba(223, 218, 218, 0.171);
			padding: 20px;
			height: 100%;
		}
		.downSide {
			margin-top: 30px;
			background-color: rgba(223, 218, 218, 0.171);
			padding: 20px;
		}
	}
}

:global {
	.fileinput,
	.thumbnail {
		border-radius: 0 !important;
		box-shadow: none !important;
	}

	.MuiTypography-body1 {
		font-size: 13px !important;
	}

	// for select search

	.select-search {
		width: 100%;
		position: relative;
		font-family: 'Nunito Sans', sans-serif;
		box-sizing: border-box;
	}

	.select-search *,
	.select-search *::after,
	.select-search *::before {
		box-sizing: inherit;
	}

	/**
     * Value wrapper
     */
	.select-search__value {
		position: relative;
		z-index: 1;
	}

	.select-search__value::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: calc(50% - 9px);
		right: 19px;
		width: 11px;
		height: 11px;
	}

	/**
     * Input
     */
	.select-search__input {
		display: block;
		height: 36px;
		width: 100%;
		padding: 0 5px;
		background: #fff;
		border: 1px solid transparent;
		box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
		outline: none;
		font-family: 'Noto Sans', sans-serif;
		font-size: 14px;
		text-align: left;
		text-overflow: ellipsis;
		line-height: 36px;
		-webkit-appearance: none;
	}

	.select-search__input::-webkit-search-decoration,
	.select-search__input::-webkit-search-cancel-button,
	.select-search__input::-webkit-search-results-button,
	.select-search__input::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	.select-search__input:not([readonly]):focus {
		cursor: pointer;
	}

	/**
     * Options wrapper
     */
	.select-search__select {
		background: #fff;
		box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
	}

	/**
     * Options
     */
	.select-search__options {
		list-style: none;
		padding-inline-start: 0;
	}

	/**
     * Option row
     */
	.select-search__row:not(:first-child) {
		border-top: 1px solid #eee;
	}

	/**
     * Option
     */
	.select-search__option {
		display: block;
		height: 36px;
		width: 100%;
		padding: 0 16px;
		background: #fff;
		border: none;
		outline: none;
		font-family: 'Noto Sans', sans-serif;
		font-size: 14px;
		text-align: left;
		cursor: pointer;
	}

	.select-search--multiple .select-search__option {
		height: 48px;
	}

	.select-search__option.is-selected {
		background: gray;
		color: #fff;
	}

	.select-search__option.is-highlighted,
	.select-search__option:not(.is-selected):hover {
		background: rgba(109, 110, 110, 0.418);
	}

	.select-search__option.is-highlighted.is-selected,
	.select-search__option.is-selected:hover {
		background: rgb(160, 158, 158);
		color: #fff;
	}

	/**
     * Group
     */
	.select-search__group-header {
		font-size: 10px;
		text-transform: uppercase;
		background: #eee;
		padding: 8px 16px;
	}

	/**
     * States
     */
	.select-search.is-disabled {
		opacity: 0.5;
	}

	// .select-search.is-loading .select-search__value::after {
	//     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
	//     background-size: 11px;
	// }

	.select-search:not(.is-disabled) .select-search__input {
		cursor: pointer;
	}

	/**
     * Modifiers
     */
	.select-search--multiple {
		border-radius: 3px;
		overflow: hidden;
	}

	.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
		transform: rotate(45deg);
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		pointer-events: none;
	}

	.select-search--multiple .select-search__input {
		cursor: pointer;
	}

	.select-search--multiple .select-search__input {
		border-radius: 3px 3px 0 0;
	}

	.select-search--multiple:not(.select-search--search) .select-search__input {
		cursor: pointer;
	}

	.select-search:not(.select-search--multiple) .select-search__input:hover {
		border-color: gray;
		cursor: pointer;
	}

	.select-search:not(.select-search--multiple) .select-search__select {
		position: absolute;
		z-index: 2;
		top: 44px;
		right: 0;
		left: 0;
		border-radius: 3px;
		overflow: auto;
		max-height: 360px;
	}

	.select-search--multiple .select-search__select {
		position: relative;
		overflow: auto;
		max-height: 260px;
		border-top: 1px solid #eee;
		border-radius: 0 0 3px 3px;
	}
}

.mainDiv {
	// font-size: 12px;
	.leftAlignCell {
		text-align: left;
	}
	.fitTitleDivCheckbox {
		height: fit-content !important;
		padding-bottom: 0 !important;
	}
	.fitTitleDiv {
		height: fit-content !important;
		padding-bottom: 0 !important;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		> span {
			margin-left: 5px;
		}
		svg {
			font-size: 12px;
		}
	}
	.inlineButton {
		font-size: 13px;
		padding: 2px;
		border: 1px solid orange;
		border-radius: 5px;
		background-color: white;
		color: orange;
		outline: none;
		width: fit-content;
		margin: 5px;
		&:hover {
			outline: none;
			background-color: orange;
			color: white;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}

	.inlineEditButton {
		color: gray;
		border: 1px solid gray;
		&:hover {
			background-color: gray;
			color: white;
		}
	}

	.inlineDeleteButton {
		color: red;
		border: 1px solid red;
		&:hover {
			background-color: red;
			color: white;
		}
	}
	.label,
	.inputLabel,
	.dropdownLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.inputLabel {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 18px;
		height: 100%;
	}
	.dropdownRow {
		margin: 20px 0;
	}
	.row,
	.inputRow {
		background-color: rgba(223, 218, 218, 0.171);
		padding: 10px 15px;
		margin: 10px 0;
	}
	.idContainer,
	.textContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		margin: 7px 0;
	}
	.buttonContainer {
		display: flex;
		height: 100%;
		align-items: center;
		button {
			width: fit-content;
		}
	}

	@media screen and (min-width: 600px) {
		.dropdownLabel {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;
		}
		.buttonContainer {
			text-align: right;
		}
		.idContainer,
		.textContainer,
		.buttonContainer {
			margin: 0 0;
		}
		.dropdownContainer {
			text-align: right;
		}
	}
	@media screen and (min-width: 960px) {
		.inputButtonContainer {
			text-align: left;
		}
	}
	@media screen and (min-width: 1280px) {
		// font-size: 12px;
	}
}

.mainDiv {
	position: relative;
	.header {
		position: sticky;
		top: 0;
		color: white;
		background-color: rgb(83, 83, 83);
		padding-bottom: 5px;
		&:first-child {
			text-align: right;
		}
	}

	.title {
		text-align: center;
		> h4 {
			font-weight: 400;
		}
	}
	.label {
		font-weight: 400;
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.mapIcon {
		transform: rotate(90deg);
	}
	.toggleContainer {
		text-align: center;
		margin: 15px 0;
		&:hover {
			cursor: pointer;
		}
		svg {
			margin-left: 5px;
			font-size: 22px;
		}
		> div {
			display: flex;
			flex-direction: row;
			justify-content: center;
			font-weight: 400;
		}
	}
	.rejectText {
		font-weight: 400;
		color: rgb(202, 10, 10);
		font-size: 16px;
		&:hover {
			cursor: pointer;
		}
	}
	.infoContainer {
		padding: 0 10px;
	}

	.footer {
		margin: 10px 0;
		display: none;
		flex-direction: row;
		justify-content: space-around;
	}
	.footerShow {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 5px 0;
	}
	.rowContainer {
		margin-top: 7px;
	}
	@media screen and (min-width: 600px) {
		width: 400px;
		.infoContainer {
			padding: 0 30px;
		}
		.rowContainer {
			padding: 5px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	}
	@media screen and(min-width: 1000px) {
		width: 700px;
	}
}

.mainDiv{
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    color: black;
    font-weight: 500;
    padding: 0 10px;
    font-size: 20px;
    .successIcon{
        >svg{
            color: rgb(20, 187, 20);
            font-size: 90px;
        }
    }
    .failIcon{
        >svg{
            color: rgb(187, 59, 20);
            font-size: 90px;
        }
    }
}
.mainDiv {
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel,
	.checkboxLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.buttonContainer {
		text-align: center;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		margin-top: 20px;
	}
	.carImageLabel {
		margin-bottom: 20px;
	}
	.dropdownRow {
		margin-top: 15px;
	}
	.dropdownContainer {
		margin-top: 10px;
	}
	.row {
		margin: 15px 0;
	}
	.dateLabel,
	.dateRow {
		margin: 15px 0;
	}
	.percentIcon {
		// color: rgba(34, 33, 33, 0.733);
		color: red;
		font-size: 10px;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.tagRow {
		margin-top: 17px;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	.imageRow {
		margin: 15px 0;
	}
	.insuranceImageContainer {
		margin-top: 15px;
	}
	.dateLabel,
	.dateRow {
		margin: 15px 0;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	@media screen and (min-width: 600px) {
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
		}
		.buttonContainer {
			text-align: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 20px;
		}
		.insuranceImageContainer {
			margin-top: 0;
			text-align: right;
			> div {
				width: 50%;
			}
		}
		.checkboxLabel,
		.imageLabel {
			padding-top: 10px;
		}
		.imageRow {
			margin: 30px 0;
		}
		.checkboxRow {
			margin: 30px 0;
		}
		.dropdownRow {
			margin-top: 20px;
		}
		.dropdownContainer {
			text-align: right;
			padding-right: 5px;
			margin-top: 0;
		}
		.content {
			padding: 30px 100px;
		}
		.buttonContainer {
			text-align: center;
		}
		.dateRow {
			margin: 30px 0;
		}
		.row {
			margin: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
			:first-child {
				margin-top: 20px;
			}
			:nth-child(2) {
				margin-top: 42px;
			}
		}
		.tagRow {
			height: 100px;
		}
		.expireContainer {
			margin-bottom: 40px;
		}
		.carImageContainer {
			text-align: right;
		}
	}
	@media screen and (min-width: 960px) {
		.insuranceImageContainer {
			text-align: right;
		}
		.content {
			padding: 30px 15px;
		}
		.tagRow {
			margin-top: 27px;
		}
	}
	@media screen and (min-width: 1280px) {
		.insuranceImageContainer {
			text-align: right;
			> div {
				width: 280px;
			}
			> img {
				border-radius: 0 !important;
			}
		}
		.carImageContainer {
			> div {
				width: 200px;
			}
		}
		.carImageLabel {
			margin-bottom: 30px;
			text-align: center;
		}
		.leftSide,
		.rightSide {
			background-color: rgba(255, 255, 255, 0.315);
			padding: 20px;
			height: 100%;
		}
		.downSide {
			margin-top: 30px;
			background-color: rgba(255, 255, 255, 0.315);
			padding: 20px;
		}
	}
}

.mainDiv{
    .cardImage{
        width: 100%;
        height: calc(100% * 1.5);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .buttonContainer{
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        // text-align: right;
        .sentence{
            color: rgba(90, 88, 88, 0.836);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            // padding-top: 5px;
            .innerSentence{
                .numberSpan{
                    color: white;
                    background-color: rgba(255, 0, 0, 0.74);
                    border-radius: 50%;
                    padding: 5px 10px;
                    text-align: center;
                }
            }
            
        }
    }

    .link{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .title{
        color: rgba(0, 0, 0, 0.466);
        text-align: right;
        padding-right: 25px;
        padding-top: 10px;
    }
    .content{
        color: rgba(10, 10, 10, 0.616);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .innerContent{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 7px;
        font-weight: 400;
        font-size: 17px;
    }
}
.mainDiv {
	overflow-x: hidden;
	padding-top: 15vh;
	padding-bottom: 20px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgb(223, 223, 223);
	// background-image: url("../../assets/img/register-background.jpg");
	// background-repeat: no-repeat;
	// background-size: cover;
	// background-position: center;
	display: flex;
	flex-direction: column;
	// justify-content: space-around;
	.listContainer {
		font-size: 16px;
		font-weight: 400;
		margin: 35px 0;
		color: black;
		> ol > li {
			margin: 20px 0;
		}
	}
	.icon {
		&:hover {
		  cursor: pointer;
		}
	  }
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel,
	.inputLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.buttonContainer {
		text-align: center;
		margin: 15px 0;
	}
	.title {
		text-align: center;
		width: 100%;
	}
	.whiteCard {
		border-radius: 2px;
		background-color: white;
		padding: 0 15px 5px 15px;
	}
	.header {
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.headerSentence {
		background-color: black;
		color: white;
		font-size: 19px;
		font-weight: 500;
		width: fit-content;
		padding: 20px 30px;
	}
	.title {
		text-align: center;
		width: 100%;
	}
	.inputLabel {
		color: rgba(70, 68, 68, 0.836);
		text-align: left;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 5px;
		margin-top: 15px;
	}
	.imageRow {
		margin-top: 40px;
	}
	.imageLabel {
		color: rgba(70, 68, 68, 0.836);
		text-align: left;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding-top: 15px;
		padding-left: 15px;
		margin-bottom: 25px;
	}
	.buttonContainer {
		margin-top: 30px;
	}
	.dropdownContainer {
		margin: 20px 0;
		.dropdownWrapper {
			margin-top: 20px;
			text-align: left;
		}
	}
	@media screen and (min-width: 600px) {
		// position: fixed;
		overflow-x: hidden;
		.inputLabel {
			margin-top: 0;
			padding-bottom: 15px;
			padding-left: 15px;
		}
		.dropdownWrapper {
			text-align: right !important;
			margin-top: 0 !important;
		}
	}
	@media screen and (min-width: 960px) {
		.dropdownWrapper {
			padding-right: 35px;
		}
		.imageLabel {
			margin-bottom: 0;
		}
	}
}

.mainDiv {
	.sentence {
		font-size: 18px;
		font-weight: 400;
        margin: 20px 0;
        color: rgb(194, 70, 70);
	}
	.button {
		font-size: 18px;
		display: flex;
		height: 50px;
		width: 100px;
		flex-direction: row;
		justify-content: space-around;
		border: none;
		outline: none;
		background-color: rgb(95, 93, 93);
		color: white;
		border-radius: 3px;
		&:hover,
		&:focus {
			background-color: rgb(68, 67, 67);
			cursor: pointer;
			border: none;
			outline: none;
		}
		> div {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	}
}

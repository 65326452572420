.mainDiv {
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.buttonContainer {
		text-align: center;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		margin-top: 20px;
	}
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.dropdownRow {
		margin-top: 15px;
	}
	.dropdownContainer {
		margin-top: 10px;
	}
	.imageLabel {
		margin: 15px 0;
	}
	.row {
		margin: 15px 0;
	}
	.dateRow {
		margin: 35px 0;
	}
	.dateLabel {
		margin: 15px 0;
	}
	.percentIcon {
		// color: rgba(34, 33, 33, 0.733);
		color: red;
		font-size: 10px;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.tagRow {
		margin-top: 17px;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	.imageRow {
		margin: 20px 0;
	}
	.imageContainer {
		> div {
			// width: 190px;
			height: 190px;
		}
	}
	@media screen and (min-width: 600px) {
		.imageLabel {
			margin: 0 0;
			padding-top: 15px;
		}
		.imageContainer {
			text-align: right;
		}
		.dropdownRow {
			margin-top: 20px;
		}
		.dropdownContainer {
			text-align: right;
			padding-right: 5px;
			margin-top: 0;
		}
		.content {
			padding: 30px 100px;
		}
		.buttonContainer {
			text-align: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 20px;
		}
		// .dateRow{
		//     margin: 30px 0;
		// }
		.row {
			margin: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
			// :first-child{
			//     margin-top: 20px;
			// }
			// :nth-child(2){
			//     margin-top: 42px;
			// }
		}
		.tagRow {
			height: 100px;
		}
		.expireContainer {
			margin-bottom: 40px;
		}
	}
	@media screen and (min-width: 960px) {
		.content {
			padding: 30px 15px;
		}
		.tagRow {
			margin-top: 27px;
		}
	}
}

.mainDiv {
	width: 100%;
	padding: 0;
	position: relative;
	.header {
		position: sticky;
		top: 0;
		color: white;
		background-color: rgb(83, 83, 83);
		padding-bottom: 5px;
		&:first-child {
			text-align: right;
		}
	}
	.toggleDiv {
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		> button {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			outline: none;
			border: none;
			background-color: rgb(226, 226, 226);
			font-weight: 400;
			font-size: 15px;
			padding: 5px 10px;
			border-radius: 15px;
			color: rgb(80, 79, 79);
			&:hover {
				cursor: pointer;
				color: black;
			}
			&:focus {
				outline: none;
				border: none;
			}
			.buttonSentence {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}
	}
	.inlineTag {
		font-size: 13px;
		padding: 1px 4px;
		width: fit-content;
		border-radius: 5px;
		margin: 4px;
		color: white;
	}
	.warningTag {
		background-color: orange;
	}
	.successTag {
		background-color: rgb(37, 177, 37);
	}
	.productsContainer {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.receiptContainer {
		background-color: rgba(194, 192, 192, 0.301);
		margin: 5px 0;
		padding: 3px 5px;
	}
	.content {
		padding: 20px 50px;
	}
	.profileImage {
		width: 70px;
		height: 70px;
	}
	.title {
		text-align: center;
		> h4 {
			font-weight: 400;
		}
	}
	.rowContainer {
		margin-top: 5px;
	}
	.label {
		font-weight: 400;
	}
	.footer {
		margin-top: 20px;
		display: none;
		flex-direction: row;
		justify-content: center;
		padding: 5px 0;
		:first-child {
			margin-right: 5px;
		}
	}
	.value {
		text-align: left;
	}
	@media screen and (min-width: 600px) {
		width: 450px;
		.value {
			text-align: right;
		}
	}
	@media screen and (min-width: 960px) {
		width: 850px;
	}
}
.miniModal {
	width: 200px;
	.miniFooter {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		padding: 5px 0;
	}
	.miniContent {
		font-size: 13px;
		margin-top: 15px;
		margin-bottom: 20px;
		text-align: center;
		padding: 0 15px;
	}
	@media screen and (min-width: 600px) {
		width: 250px;
		.miniContent {
			font-size: 15px;
		}
	}
	@media screen and (min-width: 960px) {
		width: 450px;
		.miniContent {
			font-size: 18px;
		}
	}
}

:global {
	.MuiDialog-paperWidthSm {
		max-width: fit-content !important;
	}
	.MuiPaper-rounded {
		border-radius: 0 !important;
	}
}

.mainDiv {
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.icon {
		&:hover {
			cursor: pointer;
		}
	}
	.label,
	.dateLabel,
	.imageLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.selectSearchError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		text-align: center;
	}
	.imageContainer {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	.imageError {
		text-align: center;
		color: red;
		font-size: 13px;
		font-weight: 400;
		margin: 5px;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		// line-height: 0px;
	}
	.buttonContainer {
		margin: 30px 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.row {
		margin: 15px 0;
	}
	.radioRow {
		margin: 25px 0;
	}
	.dateRow {
		margin-top: 25px 0;
	}
	.imageRow {
		margin: 25px 0;
		.insuranceImageContainer {
			margin-top: 15px;
		}
	}

	@media screen and (min-width: 600px) {
		.imageLabel {
			padding-top: 40px !important;
		}
		.insuranceImageContainer {
			margin-top: 0;
			text-align: right;
			> div {
				width: 50%;
			}
		}
		.content {
			padding: 30px 100px;
		}
		.buttonContainer {
			text-align: center;
		}
		.row {
			margin: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 5px !important;
			height: 100%;
		}
	}
	@media screen and (min-width: 960px) {
		.content {
			padding: 30px 15px;
		}
	}
	@media screen and (min-width: 1280px) {
		.leftSide,
		.rightSide {
			padding: 5px 30px;
		}
		.insuranceImageContainer {
			text-align: right;
			> div {
				width: 280px;
			}
			> img {
				border-radius: 0 !important;
			}
		}
	}
}

:global {
	.MuiFormGroup-root {
		flex-direction: row !important;
	}
}

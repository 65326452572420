.mainDiv {
	.imageContainer {
		text-align: center;
		margin: 100px 0 40px 0;
	}
	.formContainer {
		text-align: center;
		padding: 0 50px;
	}
	.timeContainer {
		margin: 15px 0;
	}
	.time {
		font-weight: 400;
	}
	.buttonContainer {
		margin-top: 15px;
	}
	@media screen and (min-width: 600px) {
		.formContainer {
			padding: 0 30%;
		}
	}
	@media screen and (min-width: 960px) {
		.formContainer {
			padding: 0 35%;
		}
	}
	@media screen and (min-width: 1280px) {
		.formContainer {
			padding: 0 40%;
		}
	}
}

.mainDivBig {
	.label {
		display: flex;
		flex-direction: column;
    justify-content: flex-end;
    text-align: left;
		// padding-bottom: 18px !important;
		height: 100%;
		font-size: 16px;
		font-weight: 400;
	}
	.imageContainer {
		text-align: center;
		margin: 100px 0 40px 0;
	}
	.formContainer {
		text-align: center;
		padding: 0 50px;
	}
	.timeContainer {
		margin: 15px 0;
	}
	.time {
		font-weight: 400;
	}
	.buttonContainer {
		margin-top: 15px;
	}
	@media screen and (min-width: 600px) {
    .label {
      padding-bottom: 18px !important;
    }
		.formContainer {
			padding: 0 20%;
		}
	}
	@media screen and (min-width: 960px) {
		.formContainer {
			padding: 0 20%;
		}
	}
	@media screen and (min-width: 1280px) {
		.formContainer {
			padding: 0 30%;
		}
	}
}

.mainDiv {
	width: 100%;
	padding: 0;
	position: relative;
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.header {
		position: sticky;
		z-index: 100;
		top: 0;
		color: white;
		background-color: rgb(83, 83, 83);
		padding-bottom: 5px;
		&:first-child {
			text-align: right;
		}
	}
	.title {
		text-align: center;
		> h4 {
			font-weight: 400;
		}
	}
	.content {
		padding: 25px;
	}
	.roleRow {
		margin: 25px 0;
	}
	.roleSubtitle {
		font-size: 15px;
		font-weight: 400;
	}
	.whiteBackground {
		background-color: rgba(255, 255, 255, 0.486);
		padding: 5px 0px 5px 10px;
	}
	.roleDescription {
		font-size: 13px;
	}
	.roleContainer {
		margin: 20px 0;
	}
	.roleTitle {
		margin-bottom: 15px;
		text-align: left;
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.tagRow {
		margin-top: 10px;
		margin-bottom: 20px !important;
	}
	.tagsContainer {
		background-color: rgba(185, 184, 184, 0.452);
	}
	.tagsContainer {
		width: 100%;
		height: 100px;
		overflow-x: auto;
		overflow-y: auto;
	}
	.label {
		font-weight: 400;
		margin: 10px 0;
	}
	.inputLabel,
	.colorLabel,
	.tagLabel {
		font-weight: 400;
	}
	.errorMessage {
		color: red;
		font-size: 10px;
		font-weight: 400;
		line-height: 0px;
		margin-bottom: 10px;
	}
	.row,
	.tagRow {
		padding: 10px 15px;
		margin: 10px 0;
	}
	.idContainer,
	.textAreaContainer {
		width: 100%;
	}
	.buttonsContainer {
		text-align: center;
	}
	.textArea {
		width: 80%;
		padding: 10px;
		height: 100px;
		resize: none;
	}

	@media screen and (min-width: 600px) {
		width: 500px;
		.tagRow {
			height: 100px;
		}

		.inputLabel {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px;
			height: 100%;
		}
		.dropdownContainer {
			text-align: right;
		}
		.idContainer,
		.textAreaContainer {
			text-align: right;
		}
		.buttonsContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
		.idContainer,
		.textContainer,
		.buttonContainer {
			margin: 0 0;
		}
		.label {
			margin: 0 0;
		}
	}
	@media screen and (min-width: 960px) {
		width: 700px;
		.tagRow {
			margin-top: 27px;
		}
	}
	@media screen and (min-width: 1280px) {
		.tagRow {
			margin-top: 35px;
		}
		.tagLabel {
			padding-top: 10px;
		}
	}
}

:global {
	.MuiDialog-paperWidthSm {
		overflow: visible !important;
	}
	.makeStyles-button-178 {
		overflow: hidden;
	}
}

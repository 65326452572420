.mainDiv {
	.buttonContainer {
		text-align: center;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		margin-top: 20px;
	}
	.icon {
		&:hover {
			cursor: pointer;
		}
	}
	.label,
	.dateLabel,
	.imageLabel,
	.tagLabel,
	.roleTitle {
		font-size: 16px;
		font-weight: 400;
	}
	.roleRow {
		margin: 25px 0;
	}
	.roleSubtitle {
		font-size: 14px;
		font-weight: 400;
	}
	.whiteBackground {
		background-color: rgba(223, 218, 218, 0.171);
		padding: 5px 0px 5px 10px;
	}
	.roleDescription {
		font-size: 13px;
	}
	.roleContainer {
		margin: 20px 0;
	}
	.roleTitle {
		margin-bottom: 15px;
		text-align: left;
	}
	.dropdownRow {
		margin-top: 15px;
	}
	.dropdownContainer {
		margin-top: 10px;
	}
	.row {
		margin: 15px 0;
	}
	.dateLabel,
	.dateRow {
		margin: 15px 0;
	}
	.percentIcon {
		// color: rgba(34, 33, 33, 0.733);
		color: red;
		font-size: 10px;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.tagRow {
		margin-top: 17px;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	@media screen and (min-width: 600px) {
		.dropdownRow {
			margin-top: 20px;
		}
		.dropdownContainer {
			text-align: right;
			padding-right: 5px;
			margin-top: 0;
		}
		.content {
			padding: 30px 100px;
		}
		.buttonContainer {
			text-align: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 20px;
		}
		// .dateRow{
		//     margin: 30px 0;
		// }
		.row {
			margin: 0;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
			:first-child {
				margin-top: 20px;
			}
			:nth-child(2) {
				margin-top: 42px;
			}
		}
		.tagRow {
			height: 100px;
		}
		.expireContainer {
			margin-bottom: 40px;
		}
	}
	@media screen and (min-width: 960px) {
		.content {
			padding: 30px 15px;
		}
		.tagRow {
			margin-top: 27px;
		}
	}
}

.mainDiv {
	margin-bottom: 50px;
	padding: 5px 0px;
	.navContainer {
		margin-bottom: 10px;
		padding: 5px 0px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding-right: 20px;
		div {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			width: fit-content;
			min-width: 100px;
			min-height: 50px;
			margin: 5px;
		}
		.nav {
			color: gray;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			text-align: center;
			padding: 0 5px;
			width: 100%;
			height: 90%;
			&:hover {
				background-color: rgba(255, 230, 0, 0.267);
				color: black;
			}
		}

		.activeNav {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			background-color: #ffd700;
			font-weight: 400;
			border-left: 5px solid black;
			text-align: center;
			color: black;
			padding: 0 5px;
			width: 100%;
			height: 90%;
			&:hover {
				background-color: #ffd700;
				color: black;
			}
		}
		.warningNav {
			&:hover {
				background-color: rgba(248, 172, 32, 0.795);
				color: black;
			}
		}
		.warningActiveNav {
			background-color: orange;
			&:hover {
				background-color: orange;
				color: black;
			}
		}
		.successNav {
			&:hover {
				background-color: rgba(157, 255, 0, 0.438);
				color: black;
			}
		}
		.successActiveNav {
			background-color: greenyellow;
			&:hover {
				background-color: greenyellow;
				color: black;
			}
		}
	}
}

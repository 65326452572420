.mainDiv {
    width: 200px;
    .header{
      >div{
        text-align: right;
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 5px 0;
    }
    .content{
        font-size: 13px;
        margin-top: 15px;
        margin-bottom: 20px;
        text-align: center;
        padding: 0 15px;
    }
    @media screen and (min-width: 600px) {
      width: 250px;
      .content{
          font-size: 15px;
      }
    }
    @media screen and (min-width: 960px) {
      width: 450px;
      .content{
          font-size: 15px;
      }
    }
  }
.mainDiv {
	position: relative;
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.scrollDownDiv {
		width: fit-content;
		position: absolute;
		top: 0;
		right: 0;
	}
	.scrollUpDiv {
		width: fit-content;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.label,
	.dateLabel,
	.imageLabel,
	.checkboxLabel,
	.mapLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.wholeError {
		text-align: center;
		margin: 20px 0;
		font-weight: 400;
		color: red;
		font-size: 16px;
		padding: 0 45px;
	}
	.selectSearchError {
		color: red;
		font-size: 12px;
	}
	.locationErrorDiv {
		font-weight: 400;
		color: red;
		margin: 5px 0;
		font-size: 15px;
	}
	.addPhone {
		text-align: left;
		margin: 20px 0;
		font-weight: 400;
		&:hover {
			cursor: pointer;
		}
	}
	.addPhoneButton {
		width: fit-content;
		border: 0.6px solid gray;
		border-radius: 20px;
		padding: 2px 4px;
		font-size: 12px;
		&:hover {
			background-color: gray;
			color: white;
		}
	}
	.eachDay {
		padding: 5px 15px;
		margin: 10px 0;
		background-color: rgba(158, 157, 157, 0.096);
	}
	.notSelectedDay {
		background-color: inherit !important;
		margin: 0;
		padding: 0;
	}
	.checkboxGroupContainer {
		text-align: left;
	}
	.checkboxContainer {
		text-align: left;
	}
	.mapLabel {
		text-align: left;
		margin-bottom: 15px;
	}
	.checkboxLabel {
		text-align: left;
	}
	.mapRow {
		margin: 25px 0;
	}
	.checkboxRow {
		margin: 25px 0;
	}
	.mapContainer {
		width: 100%;
		height: 350px;
	}
	.label {
		text-align: left;
	}
	.dropdownRow {
		margin-top: 15px;
	}
	.dropdownContainer {
		text-align: left;
		margin-top: 10px;
	}
	.row {
		border-radius: 2px;
		margin: 5px 0;
		padding: 0 5px;
	}
	.selectSearchRow {
		padding: 10px 5px !important;
		margin: 15px 0 !important;
	}
	.colorfulRow {
		background-color: rgb(231, 231, 231);
		border-radius: 2px;
		margin: 5px 0;
		padding: 0 5px;
	}
	.imageLabel {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	.dateLabel,
	.dateRow {
		margin: 15px 0;
		text-align: left;
	}
	.dateRow {
		margin: 20px;
	}
	.percentIcon {
		// color: rgba(34, 33, 33, 0.733);
		color: red;
		font-size: 10px;
	}
	.tagsContainer {
		height: fit-content;
		max-height: 100px;
		overflow-y: auto;
		background-color: rgba(255, 255, 255, 0.267);
		margin-top: 5px;
	}
	.tagRow {
		margin-top: 17px;
	}
	.selectSearchError {
		color: red;
		font-size: 13px;
		font-weight: 400;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	@media screen and (min-width: 600px) {
		.checkboxContainer {
			text-align: right;
		}
		.mapLabel {
			margin: 0 0;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-top: 15px;
		}
		.checkboxLabel {
			margin: 0 0;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-top: 10px;
		}
		.dropdownRow {
			margin-top: 20px;
		}
		.dropdownContainer {
			text-align: left;
			padding-right: 5px;
			margin-top: 0;
		}
		.content {
			padding: 30px 10px;
		}
		.buttonContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
			text-align: center;
			margin: 15px 0;
		}
		// .dateRow{
		//     margin: 30px 0;
		// }
		.row {
			margin: 5px 0;
		}
		.label {
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.dateLabel {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 0px !important;
			height: 100%;
			:first-child {
				margin-top: 20px;
			}
			:nth-child(2) {
				margin-top: 42px;
			}
		}
		.tagRow {
			height: 100px;
		}
		.expireContainer {
			margin-bottom: 40px;
		}
	}
	@media screen and (min-width: 960px) {
		.content {
			padding: 30px 15px;
		}
		.tagRow {
			margin-top: 27px;
		}
	}
}

:global {
	.makeStyles-popperResponsive-446 {
		// position: fixed !important;
	}
}

.mainDiv {
	width: 100%;
  padding: 0;
  position: relative;
	.header {
		position: sticky;
		top: 0;
		color: white;
		background-color: rgb(83, 83, 83);
		padding-bottom: 5px;
		&:first-child {
			text-align: right;
		}
	}
	.inlineButton {
		font-size: 13px;
		padding: 2px;
		border: 1px solid orange;
		border-radius: 5px;
		background-color: white;
		color: orange;
		outline: none;
		width: fit-content;
		margin: 5px;
		&:hover {
			outline: none;
			background-color: orange;
			color: white;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}

	.inlineEditButton {
		color: gray;
		border: 1px solid gray;
		&:hover {
			background-color: gray;
			color: white;
		}
	}

	.inlineDeleteButton {
		color: red;
		border: 1px solid red;
		&:hover {
			background-color: red;
			color: white;
		}
	}
	.item {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
	}
	.colorRow {
		background-color: rgba(165, 164, 164, 0.144);
		padding: 5px;
		border-radius: 2px;
		margin: 5px 0;
	}
	.profileImage {
		width: 70px;
		height: 70px;
	}
	.content {
		padding: 20px 50px;
	}
	.title {
		text-align: center;
		> h4 {
			font-weight: 400;
		}
	}
	.rowContainer {
		margin-top: 5px;
	}
	.label {
		font-weight: 400;
	}
	.value {
		text-align: left;
	}
	.footer {
		display: none;
		flex-direction: row;
		justify-content: center;
		padding: 5px 0;
	}
	.footerShow{
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 5px 0;
	}

	@media screen and (min-width: 600px) {
		width: 450px;
		.value {
			text-align: right;
		}
	}
	@media screen and (min-width: 960px) {
		width: 850px;
	}
}

:global {
	.ReactModal__Overlay,
	.ReactModal__Overlay--after-open {
		z-index: 5000 !important;
	}
}

.mainDiv {
  div {
    text-align: center;
  }
  .errorDiv{
    color: red;
    font-weight: 400;
    font-weight: 16px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
  }
  .cardFormContainer {
    background-color: white;
    padding: 10px;
    border-radius: 2px;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .fullButton {
    width: 100%;
  }
  .blueButton {
    border-radius: 3px !important;
    font-weight: 600;
    font-size: 13px;
    background-color: #556cd6;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: none !important;
    &:hover,
    &::after,
    &:visited,
    &:active,
    &:focus {
      border-radius: 3px !important;
      background-color: #556cd6 !important;
      font-family: Arial, Helvetica, sans-serif;
      box-shadow: none !important;
    }
  }
  overflow-x: hidden;
  padding-top: 15vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // background-image: url("../../assets/img/register-background.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;

  .title {
    font-size: 25px;
    margin-bottom: 80px;
    font-weight: 500;
    color: #556cd6;
    text-align: center;
    width: 100%;
  }
  .inputLabel {
    color: rgba(70, 68, 68, 0.836);
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 5px;
    margin-top: 15px;
  }
  .imageRow {
    margin-top: 40px;
  }
  .imageLabel {
    color: rgba(70, 68, 68, 0.836);
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
    padding-left: 15px;
    margin-bottom: 25px;
  }
  .buttonContainer {
    margin-top: 30px;
  }
  .dropdownContainer {
    margin: 20px 0;
    .dropdownWrapper {
      margin-top: 20px;
      text-align: left;
    }
  }
  @media screen and (min-width: 600px) {
    // position: fixed;
    overflow-x: hidden;
    .inputLabel {
      margin-top: 0;
      padding-bottom: 15px;
      padding-left: 15px;
    }
    .dropdownWrapper {
      text-align: right !important;
      margin-top: 0 !important;
    }
  }
  @media screen and (min-width: 960px) {
    .dropdownWrapper {
      padding-right: 35px;
    }
    .imageLabel {
      margin-bottom: 0;
    }
  }
}

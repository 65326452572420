.mainDiv {
	width: 100%;
	padding: 30px 0;
	.disabledButton {
		border: none;
		background-color: inherit;
		&:hover {
			cursor: default;
		}
	}
	> div {
		text-align: center;
	}
	.number {
		font-weight: 400;
		display: inline-block;
		width: fit-content;
		min-width: 30px;
	}
	.doubleArrowContainer,
	.arrowContainer {
		border: none;
		height: 50px;
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: space-around;
		text-align: -webkit-center;
		align-items: center;
		border: none;
		background-color: inherit;
		&:hover {
			cursor: pointer;
		}
	}
	.middle {
		height: 50px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	@media screen and (min-width: 600px) {
		.doubleArrowContainer,
		.arrowContainer {
			background-color: rgba(128, 128, 128, 0.185);
			&:hover {
				cursor: pointer;
				background-color: rgba(128, 128, 128, 0.384);
			}
		}
		.disabledButton {
			&:hover {
				cursor: default;
				background-color: rgba(128, 128, 128, 0.185);
			}
		}
	}
}

.mainDiv {
	.imageHint {
		color: rgb(145, 145, 145);
		font-size: 12px;
	}
	.label,
	.dateLabel,
	.imageLabel,
	.checkboxLabel {
		font-size: 16px;
		font-weight: 400;
	}
	.selectsearchDiv {
		margin: 20px 0;
	}
	.dateError {
		color: red;
		font-size: 13px;
		font-weight: 400;
		line-height: 0px;
	}
	.checkBoxContainer {
		width: 100%;
		display: flex;
	}
	.dateRow {
		margin: 30px 0;
	}
	.otherImageContainer {
		padding-top: 36px;
	}
	.buttonContainer {
		text-align: center;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		margin-top: 20px;
	}
	.addImageContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 250px;
		height: 300px;
	}
	.addImageWrapper {
		text-align: center;
	}
	@media screen and (min-width: 600px) {
		.buttonContainer {
			text-align: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 20px;
		}
		.label {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 18px !important;
			height: 100%;
		}
		.selectSearchLabel {
			padding-bottom: 0 !important;
		}
		.checkboxLabel {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-top: 7px !important;
			height: 100%;
		}
		.dateLabel {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
		}
		.checkBoxContainer {
			margin-bottom: 25px;
		}
		.categoryContainer {
			margin-top: 20px;
		}
		.availableContainer {
			margin: 20px 0;
		}
	}
	@media screen and (min-width: 960px) {
	}
	@media screen and (min-width: 1280px) {
		.leftSide {
			background-color: rgba(223, 218, 218, 0.171);
			padding-left: 25px;
			padding-bottom: 15px;
			border-radius: 3px;
			height: 100%;
		}
		.rightSide {
			height: 100%;
			background-color: rgba(223, 218, 218, 0.171);
			border-radius: 3px;
			padding: 20px 0 0 25px;
		}
		.imageRow {
			border-radius: 3px;
			background-color: rgba(223, 218, 218, 0.171);
			padding: 20px;
			margin-top: 20px;
		}
	}
}

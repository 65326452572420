.mainDiv {
	padding-bottom: 10px;
	width: 300px;
	.header {
		color: white;
		background-color: rgb(83, 83, 83);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 10px 10px 5px 20px;
	}
	.senderHeader {
		color: white;
		font-size: 11px;
		margin: 5px 0;
		font-weight: 400;
	}
	.title {
		text-align: left;
	}
	.headerTitle {
		font-size: 18px;
		font-weight: 400;
	}
	.headerDate {
		color: rgb(202, 202, 202);
		font-size: 10px;
		font-weight: 400;
	}
	.chatContainer {
		margin-top: 5px;
		padding: 10px 10px;
		margin-left: 10px;
		height: 210px;
		overflow-y: auto;
		overflow-x: hidden;
		margin-right: 5px;
		// background-color: rgba(12, 12, 12, 0.082);
		border-radius: 2px;
		.senderName {
			text-align: left;
			font-size: 11px;
			color: gray;
			font-weight: 400;
		}
		.userCommentChatContainer,
		.adminCommentChatContainer {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			.userChat {
				padding: 8px;
				width: fit-content;
				max-width: 60%;
				height: fit-content;
				overflow-x: auto;
				background-color: rgb(107, 107, 107);
				color: white;

				.messageDate {
					font-size: 7px;
					display: block;
					line-height: 5px;
					color: white;
					text-align: left;
					margin-top: 5px;
				}
			}
			.adminChat {
				padding: 8px;
				width: fit-content;
				overflow-x: auto;
				height: fit-content;
				max-width: 60%;
				background-color: cadetblue;
				color: white;

				.adminMessageDate {
					font-size: 7px;
					display: block;
					line-height: 5px;
					color: white;
					text-align: right;
					margin-top: 5px;
				}
			}
		}
		.adminCommentChatContainer {
			margin-top: 5px;
			justify-content: flex-end;
		}
		.userChatContainer {
			.chatMainContainer {
				margin: 5px 0;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
			}

			.senderName {
				text-align: left;
				font-size: 11px;
				color: gray;
				font-weight: 400;
			}
			.userChat {
				padding: 8px;
				width: fit-content;
				max-width: 60%;
				height: fit-content;
				overflow-x: auto;
				background-color: rgb(107, 107, 107);
				color: white;

				.messageDate {
					font-size: 7px;
					display: block;
					line-height: 5px;
					color: white;
					text-align: left;
					margin-top: 5px;
				}
			}
		}
		.adminChatContainer {
			.adminChatmainContainer {
				margin: 5px 0;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
			}

			.senderName {
				text-align: right;
				font-size: 11px;
				color: gray;
				font-weight: 400;
			}
			.adminChat {
				padding: 8px;
				width: fit-content;
				overflow-x: auto;
				height: fit-content;
				max-width: 60%;
				background-color: cadetblue;
				color: white;

				.adminMessageDate {
					font-size: 7px;
					display: block;
					line-height: 5px;
					color: white;
					text-align: right;
					margin-top: 5px;
				}
			}
		}
		.inputContainer {
			margin-top: 10px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			.textArea {
				min-height: 100px;
				max-height: 800px;
				width: 60%;
				resize: none;
				border: none;
				padding: 8px;
				background-color: white;
				border-radius: 15px;
				border-bottom-left-radius: 0;
			}
		}
	}
	.footer {
		margin: 10px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
	.footerPart {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 5px;
		margin: 5px 10px 0px 10px;
		background-color: rgb(231, 231, 231);
	}
	.input {
		background-color: inherit;
		border: none;
		outline: none;
	}
	@media screen and (min-width: 600px) {
		width: 400px;
	}
	@media screen and(min-width: 1000px) {
		width: 500px;
	}
}

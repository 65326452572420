.mainDiv {
	width: 90%;
	padding: 0;
	position: relative;
	.header {
		position: sticky;
		top: 0;
		color: white;
		background-color: rgb(83, 83, 83);
		padding-bottom: 5px;
		&:first-child {
			text-align: right;
		}
	}
	.inlineButton {
		font-size: 13px;
		padding: 2px;
		border: 1px solid orange;
		border-radius: 5px;
		background-color: white;
		color: orange;
		outline: none;
		width: fit-content;
		margin: 5px;
		&:hover {
			outline: none;
			background-color: orange;
			color: white;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}

	.inlineEditButton {
		color: gray;
		border: 1px solid gray;
		&:hover {
			background-color: gray;
			color: white;
		}
	}

	.inlineDeleteButton {
		color: red;
		border: 1px solid red;
		&:hover {
			background-color: red;
			color: white;
		}
	}
	.paymentRow {
		background-color: rgba(189, 185, 185, 0.329);
		padding: 4px;
		margin: 10px 0px;
		border-radius: 2px;

		.paymentInfoWrapper {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
		.paymentInfo {
			margin-top: 10px;
		}
		.checkboxContainer,
		.iconContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;
		}
		.iconContainer {
			// margin-top: 11px;
		}

		.paymentId {
			&:hover {
				cursor: pointer;
			}
			font-weight: 400;
		}
		.paymentDate {
			font-size: 11px;
		}
	}
	.copyClipboard {
		&:hover {
			cursor: pointer;
			font-weight: 400;
		}
	}
	.content {
		padding: 20px 50px;
	}
	.title {
		text-align: center;
		> h4 {
			font-weight: 400;
		}
	}
	.rowContainer {
		margin-top: 5px;
	}
	.label {
		font-weight: 400;
	}
	.value {
		text-align: left;
	}
	.footer {
		margin-top: 20px;
		display: none;
		flex-direction: row;
		justify-content: center;
		padding: 5px 0;
	}
	.footerShow {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 5px 0;
	}

	@media screen and (min-width: 600px) {
		width: 450px;
		.value {
			text-align: right;
		}
		.badgeContainer {
			background-color: rgba(112, 110, 110, 0.096);
			height: fit-content;
			max-height: 200px;
			overflow-y: auto;
		}
	}
	@media screen and (min-width: 960px) {
		width: 850px;
	}
}
